import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <h1>404 NOT FOUND</h1>
    <p>We're sorry, the page you are trying to go to does not exist.</p>
    <p>Find your way back <Link href="/">HOME</Link>!</p>
  </Layout>
)

export default NotFoundPage
